import React, { useEffect, useState, useRef } from 'react';
import { db, auth } from '../firebaseConfig';
import { doc, getDoc, addDoc, collection, query, where, getDocs, updateDoc, setDoc, deleteField } from 'firebase/firestore';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import MenuBar from '../components/MenuBar';
import { useSwipeable } from 'react-swipeable';
import '../styles/OtherMemberProfile.css';
import { BiSolidLike } from "react-icons/bi";
import { IoChatbubbles } from "react-icons/io5";
import { FiMoreHorizontal } from 'react-icons/fi';
import { IoArrowRedoSharp } from "react-icons/io5";

const OtherMemberProfile = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const members = state?.members || [];
  const [profile, setProfile] = useState(null);
  const [user, loading, error] = useAuthState(auth);
  const [alreadyLiked, setAlreadyLiked] = useState(false);
  const [receivedLike, setReceivedLike] = useState(false);
  const [approved, setApproved] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const [rejectedByUser, setRejectedByUser] = useState(false);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const navigate = useNavigate();
  const optionsRef = useRef(null);

  const resetStates = () => {
    setAlreadyLiked(false);
    setReceivedLike(false);
    setApproved(false);
    setChatId(null);
    setRejectedByUser(false);
  };

  const fetchProfile = async (memberId) => {
    const docRef = doc(db, 'members', memberId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setProfile(docSnap.data());
    }
  };

  const calculateAge = (birthday) => {
    if (!birthday) return '--';
    const birthDate = new Date(birthday);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const checkIfLiked = async (memberId) => {
    if (user) {
      const sentQuery = query(
        collection(db, 'likes'),
        where('from', '==', user.uid),
        where('to', '==', memberId)
      );
      const sentQuerySnapshot = await getDocs(sentQuery);
      if (!sentQuerySnapshot.empty) {
        const sentLikeDoc = sentQuerySnapshot.docs[0];
        if (sentLikeDoc.data().status === 'approved') {
          setApproved(true);
          const chatQuery = query(
            collection(db, 'chats'),
            where('participants', 'array-contains', user.uid)
          );
          const chatSnapshot = await getDocs(chatQuery);
          const chat = chatSnapshot.docs.find(doc => doc.data().participants.includes(memberId));
          if (chat) {
            setChatId(chat.id);
          }
        } else {
          setAlreadyLiked(true);
        }
      }

      const receivedQuery = query(
        collection(db, 'likes'),
        where('from', '==', memberId),
        where('to', '==', user.uid),
        where('status', '==', 'pending')
      );
      const receivedQuerySnapshot = await getDocs(receivedQuery);
      setReceivedLike(!receivedQuerySnapshot.empty);

      const approvedQuery = query(
        collection(db, 'likes'),
        where('from', '==', memberId),
        where('to', '==', user.uid),
        where('status', '==', 'approved')
      );
      const approvedQuerySnapshot = await getDocs(approvedQuery);
      if (!approvedQuerySnapshot.empty) {
        setApproved(true);
        const chatQuery = query(
          collection(db, 'chats'),
          where('participants', 'array-contains', user.uid)
        );
        const chatSnapshot = await getDocs(chatQuery);
        const chat = chatSnapshot.docs.find(doc => doc.data().participants.includes(memberId));
        if (chat) {
          setChatId(chat.id);
        }
      }
    }
  };

  const checkIfUserRejected = async (memberId) => {
    if (user) {
      const rejectedQuery = query(
        collection(db, 'likes'),
        where('from', '==', memberId),
        where('to', '==', user.uid),
        where('status', '==', 'rejected')
      );
      const rejectedQuerySnapshot = await getDocs(rejectedQuery);
      return !rejectedQuerySnapshot.empty;
    }
    return false;
  };

  const checkIfChatting = async (memberId) => {
    if (user) {
      const chatQuery = query(
        collection(db, 'chats'),
        where('participants', 'array-contains', user.uid)
      );
      const chatSnapshot = await getDocs(chatQuery);
      const chat = chatSnapshot.docs.find(doc => doc.data().participants.includes(memberId));
      if (chat) {
        setChatId(chat.id);
      }
    }
  };

  const checkIfHidden = async () => {
    if (user) {
      const memberNotDisplayRef = doc(db, 'member_not_display', id);
      const docSnap = await getDoc(memberNotDisplayRef);
      if (docSnap.exists() && docSnap.data()[user.uid]) {
        setIsHidden(true);
      } else {
        setIsHidden(false);
      }
    }
  };

  useEffect(() => {
    if (id) {
      resetStates();
      fetchProfile(id);
      checkIfHidden();
    }
  }, [id]);

  useEffect(() => {
    if (id && user) {
      checkIfLiked(id);
      checkIfChatting(id);
      checkIfUserRejected(id).then(setRejectedByUser);
    }
  }, [id, user]);

  useEffect(() => {
    console.log("Members in OtherMemberProfile:", members); // デバッグ用コンソールログ
  }, [members]);

  const handleLike = async () => {
    if (user) {
      const isRejected = await checkIfUserRejected(id);
      if (isRejected) {
        alert('このユーザーにはこれ以上いいねを送ることができません。');
        return;
      }

      try {
        await addDoc(collection(db, 'likes'), {
          from: user.uid,
          to: id,
          status: 'pending',
          createdAt: new Date(),
          check: false
        });
        alert('いいねを送信しました');
        setAlreadyLiked(true);
      } catch (error) {
        console.error('Error sending like:', error.message);
      }
    } else {
      console.error('No user is authenticated');
    }
  };

  const handleApprove = async () => {
    if (user) {
      try {
        const receivedQuery = query(
          collection(db, 'likes'),
          where('from', '==', id),
          where('to', '==', user.uid),
          where('status', '==', 'pending')
        );
        const receivedQuerySnapshot = await getDocs(receivedQuery);

        if (!receivedQuerySnapshot.empty) {
          const likeDoc = receivedQuerySnapshot.docs[0];
          await updateDoc(likeDoc.ref, {
            status: 'approved'
          });
          alert('マッチングしました！');
          setApproved(true);

          // チャットの作成
          const chatDocRef = await addDoc(collection(db, 'chats'), {
            participants: [user.uid, id],
            createdAt: new Date()
          });
          setChatId(chatDocRef.id);
        }
      } catch (error) {
        console.error('Error approving like:', error.message);
      }
    }
  };

  const handleReject = async () => {
    if (user) {
      try {
        const receivedQuery = query(
          collection(db, 'likes'),
          where('from', '==', id),
          where('to', '==', user.uid),
          where('status', '==', 'pending')
        );
        const receivedQuerySnapshot = await getDocs(receivedQuery);

        if (!receivedQuerySnapshot.empty) {
          const likeDoc = receivedQuerySnapshot.docs[0];
          await updateDoc(likeDoc.ref, {
            status: 'rejected'
          });
          alert('Like rejected.');
          setReceivedLike(false);
          setRejectedByUser(true);
        }
      } catch (error) {
        console.error('Error rejecting like:', error.message);
      }
    }
  };

  const openModal = (url) => {
    setModalImage(url);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  const handlePreviousProfile = () => {
    console.log("Previous profile button clicked"); // デバッグ用コンソールログ
    if (members && members.length > 0) {
      const currentIndex = members.findIndex(member => member.id === id);
      console.log("Current Index (Previous):", currentIndex); // デバッグ用コンソールログ
      if (currentIndex > 0) {
        navigate(`/other_member_profile/${members[currentIndex - 1].id}`, { state: { members } });
      }
    }
  };

  const handleNextProfile = () => {
    console.log("Next profile button clicked"); // デバッグ用コンソールログ
    if (members && members.length > 0) {
      const currentIndex = members.findIndex(member => member.id === id);
      console.log("Current Index (Next):", currentIndex); // デバッグ用コンソールログ
      if (currentIndex < members.length - 1) {
        navigate(`/other_member_profile/${members[currentIndex + 1].id}`, { state: { members } });
      }
    }
  };

  const handleOptionsToggle = () => {
    setIsOptionsVisible(!isOptionsVisible);
  };

  const handleOptionSelect = async (option) => {
    if (option === 'hide' && user) {
      try {
        const memberNotDisplayRef = collection(db, 'member_not_display');
        const docRef = doc(memberNotDisplayRef, id);
        await setDoc(docRef, {
          [user.uid]: true
        }, { merge: true });
        alert('このユーザーに対し自分のプロフィールを非表示にしました。');
        setIsHidden(true);
      } catch (error) {
        console.error('Error hiding profile:', error.message);
      }
    } else if (option === 'unhide' && user) {
      try {
        const memberNotDisplayRef = collection(db, 'member_not_display');
        const docRef = doc(memberNotDisplayRef, id);
        await updateDoc(docRef, {
          [user.uid]: deleteField()
        });
        alert('このユーザーに対し自分のプロフィールの非表示を取り消しました。');
        setIsHidden(false);
      } catch (error) {
        console.error('Error unhiding profile:', error.message);
      }
    } else if (option === 'report') {
      navigate(`/report/${id}`);
    }
    setIsOptionsVisible(false);
  };

  const handleClickOutside = (event) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setIsOptionsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      console.log("Swiped left"); // デバッグ用コンソールログ
      handleNextProfile();
    },
    onSwipedRight: () => {
      console.log("Swiped right"); // デバッグ用コンソールログ
      handlePreviousProfile();
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!profile) return <div>Loading profile...</div>;

  return (
    <div className="other-member-profile-container" {...handlers}>
      {members.length > 1 && (
        <>
          <button onClick={handlePreviousProfile} className="arrow left-arrow">&#9664;</button>
          <button onClick={handleNextProfile} className="arrow right-arrow">&#9654;</button>
        </>
      )}
      <div className="other-member-profile-header">
        <div className="options-container" ref={optionsRef}>
          <FiMoreHorizontal className="options-icon" onClick={handleOptionsToggle} />
          {isOptionsVisible && (
            <div className="options-menu">
              {isHidden ? (
                <div onClick={() => handleOptionSelect('unhide')}>・このユーザーに対し自分のプロフィールの非表示を取り消す</div>
              ) : (
                <div onClick={() => handleOptionSelect('hide')}>・このユーザーに対し自分のプロフィールを非表示</div>
              )}
              <div onClick={() => handleOptionSelect('report')}>・通報</div>
            </div>
          )}
        </div>
        <img
          src={profile.photoURL || '/images/default.png'}
          alt="Profile"
          className="other-member-profile-image"
          onClick={() => openModal(profile.photoURL || '/images/default.png')}
        />
        <div className="other-member-sub-images">
          {[profile.sub_photoURL_1, profile.sub_photoURL_2, profile.sub_photoURL_3].map((url, index) => (
            <img
              key={index}
              src={url || '/images/default.png'}
              alt={`Sub ${index + 1}`}
              className="other-member-sub-image"
              onClick={() => openModal(url || '/images/default.png')}
            />
          ))}
        </div>
        <h2 className="other-member-profile-name">{profile.name || '--'}</h2>
        {profile.hashtags && profile.hashtags.length > 0 && (
          <div className="other-member-profile-hashtags">
            {profile.hashtags.map((tag, index) => (
              <span key={index} className="other-member-profile-hashtag">#{tag}</span>
            ))}
          </div>
        )}
        <p className="other-member-profile-bio"><strong></strong> {profile.bio || '--'}</p>
      </div>
      <div className="other-member-profile-details">
        {profile.birthday && (
          <p><strong>年齢:</strong> {calculateAge(profile.birthday)}歳</p>
        )}
        {profile.gender && (
          <p><strong>性別:</strong> {profile.gender}</p>
        )}
        {profile.sexualOrientation && (
          <p><strong>性的嗜好:</strong> {profile.sexualOrientation}</p>
        )}
        {profile.location && (
          <p><strong>居住地:</strong> {profile.location}</p>
        )}
        {profile.major && (
          <p><strong>専攻:</strong> {profile.major}</p>
        )}
        {profile.grade && (
          <p><strong>学年:</strong> {profile.grade}</p>
        )}
        {profile.schoolName && (
          <p><strong>所属大学:</strong> {profile.schoolName}</p>
        )}
        {profile.affiliation && ( // 所属学会名を表示
          <p><strong>所属学会名:</strong> {profile.affiliation}</p>
        )}
        {profile.height && (
          <p><strong>身長:</strong> {profile.height}cm</p>
        )}
      </div>
      <div className="other-member-profile-actions">
        {approved || chatId ? (
          <Link to={`/chat/${chatId}`} className="other-member-profile-button"><IoChatbubbles /></Link>
        ) : receivedLike ? (
          <div className="iine-button-container">
            <button className="other-member-profile-button other-approve" onClick={handleApprove}><BiSolidLike /></button>
            <button className="other-member-profile-button other-reject" onClick={handleReject}><IoArrowRedoSharp /></button>
          </div>
        ) : rejectedByUser ? (
          <div className="other-member-profile-button other-rejected"><IoArrowRedoSharp /></div>
        ) : (
          <button 
            className={`other-member-profile-button ${alreadyLiked ? 'liked' : ''}`} 
            onClick={handleLike} 
            disabled={alreadyLiked}
          >
            {alreadyLiked ? <BiSolidLike /> : <><BiSolidLike /></>}
          </button>
        )}
      </div>
      {modalImage && (
        <div className="modal" onClick={closeModal}>
          <span className="close" onClick={closeModal}>&times;</span>
          <img className="modal-content" src={modalImage} alt="Modal" />
        </div>
      )}
      <MenuBar />
    </div>
  );
};

export default OtherMemberProfile;
