import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../../firebaseConfig';
import { deleteUser, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { doc, deleteDoc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import '../../styles/SettingAccountDelete.css';

const SettingAccountDelete = () => {
  const [user] = useAuthState(auth);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    if (!user) {
      setError('ユーザーがログインしていません。');
      return;
    }

    if (!reason) {
      setError('削除理由を選択してください。');
      return;
    }

    if (reason === 'other' && !otherReason.trim()) {
      setError('その他の理由を入力してください。');
      return;
    }

    if (!password) {
      setError('パスワードを入力してください。');
      return;
    }

    const confirmed = window.confirm('本当に消去しますか？');
    if (!confirmed) return;

    try {
      // アカウント削除の前にユーザーを再認証
      const credential = EmailAuthProvider.credential(user.email, password);
      await reauthenticateWithCredential(user, credential);

      // 削除理由を保存
      await setDoc(doc(db, 'account_deletion_reasons', user.uid), {
        reason: reason === 'other' ? otherReason : reason,
        timestamp: new Date(),
      });

      // likesコレクションのドキュメントを削除
      const likesCollection = collection(db, 'likes');
      const fromQuery = query(likesCollection, where('from', '==', user.uid));
      const toQuery = query(likesCollection, where('to', '==', user.uid));

      const fromSnapshot = await getDocs(fromQuery);
      const toSnapshot = await getDocs(toQuery);

      const deleteLikesPromises = [
        ...fromSnapshot.docs.map((docSnapshot) => deleteDoc(doc(db, 'likes', docSnapshot.id))),
        ...toSnapshot.docs.map((docSnapshot) => deleteDoc(doc(db, 'likes', docSnapshot.id))),
      ];

      await Promise.all(deleteLikesPromises);

      // chatsコレクションのドキュメントを削除
      const chatsCollection = collection(db, 'chats');
      const chatsQuery = query(chatsCollection, where('participants', 'array-contains', user.uid));

      const chatsSnapshot = await getDocs(chatsQuery);

      const deleteChatsPromises = chatsSnapshot.docs.map((docSnapshot) => deleteDoc(doc(db, 'chats', docSnapshot.id)));

      await Promise.all(deleteChatsPromises);

      // Firestoreからユーザー情報を削除
      await deleteDoc(doc(db, 'users', user.uid));
      await deleteDoc(doc(db, 'tokens', user.uid));
      await deleteDoc(doc(db, 'filterOptions', user.uid));
      await deleteDoc(doc(db, 'push_settings', user.uid));
      await deleteDoc(doc(db, 'members', user.uid));

      // Firebase Authenticationからユーザーを削除
      await deleteUser(user);
      setSuccess('アカウントが削除されました。');
      navigate('/login');
    } catch (error) {
      setError('アカウント削除中にエラーが発生しました: ' + error.message);
    }
  };

  return (
    <div className="setting-account-delete-container">
      <div className="header">
        <Link to="/settings" className="back-link-cont">←</Link>
        <p className="settings-title-cont">アカウント削除</p>
      </div>
      <div className="warning">
        <p>アカウントを削除すると、以下のデータがすべて消去されます。この操作は取り消しできませんので、ご注意ください。</p>
        <ul>
          <li>プロフィール情報</li>
          <li>チャット履歴</li>
          <li>いいね情報</li>
          <li>その他すべての関連データ</li>
        </ul>
      </div>
      <div className="reason-container">
        <p>退会理由を選択してください:</p>
        <label>
          <input
            type="radio"
            value="not_useful"
            checked={reason === 'not_useful'}
            onChange={() => setReason('not_useful')}
          />
          使い勝手が悪い
        </label>
        <label>
          <input
            type="radio"
            value="privacy_concerns"
            checked={reason === 'privacy_concerns'}
            onChange={() => setReason('privacy_concerns')}
          />
          プライバシーの懸念
        </label>
        <label>
          <input
            type="radio"
            value="other_service"
            checked={reason === 'other_service'}
            onChange={() => setReason('other_service')}
          />
          他のサービスを使用
        </label>
        <label>
          <input
            type="radio"
            value="other"
            checked={reason === 'other'}
            onChange={() => setReason('other')}
          />
          その他
        </label>
        {reason === 'other' && (
          <input
            type="text"
            value={otherReason}
            onChange={(e) => setOtherReason(e.target.value)}
            placeholder="理由を入力してください"
          />
        )}
      </div>
      <div className="password-container">
        <p>パスワードを入力してください:</p>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="パスワード"
        />
      </div>
      <button className="delete-button" onClick={handleDeleteAccount}>アカウントを削除する</button>
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}
    </div>
  );
};

export default SettingAccountDelete;
