import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { db, auth } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import Login from './pages/Login';
import Signup from './pages/Signup';
import MemberSearch from './pages/MemberSearch';
import OtherMemberProfile from './pages/OtherMemberProfile';
import Iine from './pages/Iine';
import Inbox from './pages/Inbox';
import Chat from './pages/Chat';
import ProfileEdit from './pages/ProfileEdit';
import CompleteProfile from './pages/CompleteProfile';
import FilteredMembers from './pages/FilteredMembers';
import Settings from './pages/Settings';
import Accountsettings from './pages/Accountsettings';
import AgeVerification from './pages/AgeVerification';
import AdminDashboard from './pages/admin/AdminDashboard';
import SettingPush from './pages/settings/SettingPush';
import SettingMail from './pages/settings/SettingMail';
import SettingPass from './pages/settings/SettingPass';
import SettingAccountDelete from './pages/settings/SettingAccountDelete';
import SettingNotification from './pages/settings/SettingNotification';
import AdminNotification from './pages/admin/AdminNotification';
import AdminBannedUser from './pages/admin/AdminBannedUser';
import AdminInquiry from './pages/admin/AdminInquiry';
import AdminAgeVerification from './pages/admin/AdminAgeVerification';
import NotificationDetail from './pages/settings/NotificationDetail';
import SettingInquiry from './pages/settings/SettingInquiry';
import OtherMemberReport from './pages/OtherMemberReport';
import AdminSuspendUser from './pages/admin/AdminSuspendUser';
import IineNotDisplay from './pages/IineNotDisplay';
import ChatNotDisplay from './pages/settings/ChatNotDisplay';
import CallComponent from './pages/call/CallComponent';
import { CallProvider } from './components/CallContext';
import CallModal from './components/CallModal';
import CallListener from './components/CallListener';
import useNetworkStatus from './hooks/useNetworkStatus';
import CheckoutButton from './components/CheckoutButton';
import LandingPage from './pages/LandingPage';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import LegalNotice from './pages/LegalNotice';
import PasswordResetEmail from './pages/PasswordResetEmail';
import PrivateRoute from './components/PrivateRoute';
import PaidServices from './pages/settings/PaidServices'; // 有料化ページのインポート

const App = () => {
  const [user, loading] = useAuthState(auth);
  const [isSuspended, setIsSuspended] = useState(false);
  const isOnline = useNetworkStatus();

  useEffect(() => {
    const checkSuspendedUser = async () => {
      if (user) {
        const docRef = doc(db, 'suspended_user', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setIsSuspended(true);
        }
      }
    };

    if (user) {
      checkSuspendedUser();
    }
  }, [user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (isSuspended) {
    return <div>使用できません</div>;
  }

  if (!isOnline) {
    return <div>オフラインです。インターネット接続を確認してください。</div>;
  }

  return (
    <CallProvider>
      {user && <CallListener userId={user.uid} />}
      <CallModal />
      <Routes>
        <Route path="/landing" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/reset-password" element={<PasswordResetEmail />} />
        <Route
          path="/"
          element={<Navigate to={user ? "/member_search" : "/landing"} />}
        />
        <Route
          path="/complete-profile"
          element={
            <PrivateRoute>
              <CompleteProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/member_search"
          element={
            <PrivateRoute>
              <MemberSearch />
            </PrivateRoute>
          }
        />
        <Route
          path="/other_member_profile/:id"
          element={
            <PrivateRoute>
              <OtherMemberProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/iine"
          element={
            <PrivateRoute>
              <Iine />
            </PrivateRoute>
          }
        />
        <Route
          path="/inbox"
          element={
            <PrivateRoute>
              <Inbox />
            </PrivateRoute>
          }
        />
        <Route
          path="/chat/:chatId"
          element={
            <PrivateRoute>
              <Chat />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile_edit"
          element={
            <PrivateRoute>
              <ProfileEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          path="/accountsettings"
          element={
            <PrivateRoute>
              <Accountsettings />
            </PrivateRoute>
          }
        />
        <Route
          path="/age_verification"
          element={
            <PrivateRoute>
              <AgeVerification />
            </PrivateRoute>
          }
        />
        <Route
          path="/filtered_members"
          element={
            <PrivateRoute>
              <FilteredMembers />
            </PrivateRoute>
          }
        />
        <Route
          path="/settingpush"
          element={
            <PrivateRoute>
              <SettingPush />
            </PrivateRoute>
          }
        />
        <Route
          path="/settingmail"
          element={
            <PrivateRoute>
              <SettingMail />
            </PrivateRoute>
          }
        />
        <Route
          path="/settingpass"
          element={
            <PrivateRoute>
              <SettingPass />
            </PrivateRoute>
          }
        />
        <Route
          path="/settingaccountdelete"
          element={
            <PrivateRoute>
              <SettingAccountDelete />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <AdminDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/settingnotification"
          element={
            <PrivateRoute>
              <SettingNotification />
            </PrivateRoute>
          }
        />
        <Route
          path="/adminnotification"
          element={
            <PrivateRoute>
              <AdminNotification />
            </PrivateRoute>
          }
        />
        <Route
          path="/adminbanneduser"
          element={
            <PrivateRoute>
              <AdminBannedUser />
            </PrivateRoute>
          }
        />
        <Route
          path="/admininquiry"
          element={
            <PrivateRoute>
              <AdminInquiry />
            </PrivateRoute>
          }
        />
        <Route
          path="/adminageverification"
          element={
            <PrivateRoute>
              <AdminAgeVerification />
            </PrivateRoute>
          }
        />
        <Route
          path="/notification/:id"
          element={
            <PrivateRoute>
              <NotificationDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/settinginquiry"
          element={
            <PrivateRoute>
              <SettingInquiry />
            </PrivateRoute>
          }
        />
        <Route
          path="/report/:id"
          element={
            <PrivateRoute>
              <OtherMemberReport />
            </PrivateRoute>
          }
        />
        <Route
          path="/adminsuspenduser"
          element={
            <PrivateRoute>
              <AdminSuspendUser />
            </PrivateRoute>
          }
        />
        <Route
          path="/iine-not-display"
          element={
            <PrivateRoute>
              <IineNotDisplay />
            </PrivateRoute>
          }
        />
        <Route
          path="/chat-not-display"
          element={
            <PrivateRoute>
              <ChatNotDisplay />
            </PrivateRoute>
          }
        />
        <Route
          path="/call/:chatId"
          element={
            <PrivateRoute>
              <CallComponent />
            </PrivateRoute>
          }
        />
        <Route
          path="/checkout"
          element={
            <PrivateRoute>
              <CheckoutButton userId={user ? user.uid : null} />
            </PrivateRoute>
          }
        />
        <Route
          path="/paidservices" // 有料化ページのルートを追加
          element={
            <PrivateRoute>
              <PaidServices />
            </PrivateRoute>
          }
        />
        <Route
          path="/terms-of-service"
          element={<TermsOfService />}
        />
        <Route
          path="/privacy-policy"
          element={<PrivacyPolicy />}
        />
        <Route
          path="/legal-notice"
          element={<LegalNotice />}
        />
        <Route
          path="*"
          element={<Navigate to={user ? "/member_search" : "/landing"} />}
        />
      </Routes>
    </CallProvider>
  );
};

export default App;
