import React from 'react';

const LegalNotice = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '400px', margin: '0 auto', textAlign: 'left'}}>
      <h1>特定商取引法に基づく表示</h1>

      <h2>販売業者</h2>
      <p>谷口勇貴</p>

      <h2>運営責任者</h2>
      <p>谷口勇貴</p>

      <h2>所在地</h2>
      <p>大阪府守口市八雲中町１丁目３−１ ヴィルメール守口1701号室</p>

      <h2>お問い合わせ先</h2>
      <p>メールアドレス: info@insei-match.net</p>

      <h2>販売価格</h2>
      <p>購入手続の際に画面に表示されます。<br></br>
なお、販売価格以外に、インターネット接続料金、通信料金等はお客様のご負担となります。</p>

      <h2>お支払い方法</h2>
      <p>クレジットカード決済</p>

      <h2>商品のお引渡し時期</h2>
      <p>購入手続の際に特別な定めを置いている場合を除き、購入手続完了後、直ちにご利用いただけます。</p>

      <h2>返品・キャンセルについて</h2>
      <p>・購入手続完了後のお客様ご都合によるキャンセル（中途退会による場合を含みます）、払戻しはできません。<br></br><br></br>
      ・お客様が解約手続（自動更新の停止）を行わない限り、購入したサブスクリプションサービスの役務提供期間が満了する度に自動的に更新が繰り返され、購入したサブスクリプションサービスの料金が決済されます。</p>

      <h2>動作環境</h2>
      <p>本マッチングアプリは、以下の動作環境での利用を推奨しています。ご利用の端末やブラウザがこれらの条件を満たしていない場合、正常に動作しない可能性があります。
        <br></br>
        1. 対応ブラウザ
最新版のGoogle Chrome
最新版のMozilla Firefox
最新版のSafari
最新版のMicrosoft Edge
注: Internet Explorerでは動作しません。最新のブラウザをご利用ください。
      </p>

      <h2>その他</h2>
      <p>[その他特記事項があれば記載]</p>
    </div>
  );
};

export default LegalNotice;
