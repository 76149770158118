import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { auth } from '../../firebaseConfig';  // Firebaseのauthをインポート
import '../../styles/SettingMail.css'; // 同じスタイルを使用

// Stripeの公開可能キーを使用してStripeのインスタンスをロード
const stripePromise = loadStripe('pk_live_51J2BcKAq7sm3T386aoWY4c2mRoxcLn0ySoCmLkstoNl0BTopyGKfrsywBFS1XRsPv6dte2tBqZX8ZWPqr9oX8bH200PlX1Mdmj');

const PaidServices = () => {
  const [selectedPlan, setSelectedPlan] = useState('');
  const [success, setSuccess] = useState(false);
  const [paymentError, setPaymentError] = useState('');

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  const handleSubscribe = async () => {
    const stripe = await stripePromise;

    const user = auth.currentUser;
    if (!user) {
      console.error('User not authenticated');
      setPaymentError('ユーザーが認証されていません。');
      return;
    }

    const productName = selectedPlan === 'premium' ? 'Premium Plan' : 'Basic Plan';
    const amount = selectedPlan === 'premium' ? 980 : 480;  // 980円と480円をセン単位で設定

    const userId = user.uid;

    try {
      // Firebase Cloud Functionsのエンドポイントにリクエストを送信
      const response = await fetch('https://asia-northeast2-macth-app.cloudfunctions.net/createStripeCheckoutSession', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ productName, amount, userId }),  // 商品名、金額、ユーザーIDを送信
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const session = await response.json();  // セッションIDを含むレスポンスを取得

      // StripeのCheckoutセッションにリダイレクト
      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.error(result.error.message);
        setPaymentError(result.error.message);
      } else {
        setSuccess(true);
        setPaymentError('');
      }
    } catch (error) {
      console.error('Error during checkout:', error);
      setPaymentError('支払いに失敗しました: ' + error.message);
    }
  };

  return (
    <div className="setting-mail-container">
      <div className="header">
        <Link to="/accountsettings" className="back-link-cont">←</Link>
        <p className="settings-title-cont">有料プラン</p>
      </div>
      <div className="plan-selection">
        <h3>プランを選択してください:</h3>
        <div className="plan-options">
          <label>
            <input
              type="radio"
              name="plan"
              value="basic"
              checked={selectedPlan === 'basic'}
              onChange={() => handlePlanSelect('basic')}
            />
            ベーシックプラン - 480円/月
          </label>
          <label>
            <input
              type="radio"
              name="plan"
              value="premium"
              checked={selectedPlan === 'premium'}
              onChange={() => handlePlanSelect('premium')}
            />
            プレミアムプラン - 980円/月
          </label>
        </div>
      </div>
      <button onClick={handleSubscribe} className="change-email-button" disabled={!selectedPlan}>
        サブスクライブ
      </button>
      {success && <div className="success-message">サブスクライブが完了しました。</div>}
      {paymentError && <div className="error-message">{paymentError}</div>}
    </div>
  );
};

export default PaidServices;
