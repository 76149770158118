import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../firebaseConfig';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import '../styles/FilteredMembers.css';

const FilteredMembers = () => {
  const [filterOption, setFilterOption] = useState({
    gender: 'all',
    minAge: 18,
    maxAge: 70,
    location: 'all',
    major: 'all'
  });
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    const loadFilterOptions = async () => {
      if (user) {
        const filterDocRef = doc(db, 'filterOptions', user.uid);
        const filterDoc = await getDoc(filterDocRef);
        if (filterDoc.exists()) {
          setFilterOption(filterDoc.data());
        }
      }
    };

    loadFilterOptions();
  }, [user]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterOption(prevState => ({ ...prevState, [name]: value }));
  };

  const handleApplyFilter = async () => {
    if (user) {
      const filterDocRef = doc(db, 'filterOptions', user.uid);
      await setDoc(filterDocRef, filterOption);
      navigate('/member_search');
    }
  };

  return (
    <div className="filtered-members-container">
      <h2 className="filtered-members-title">絞り込み設定</h2>
      <div className="filtered-members-form-group">
        <label>性別:</label>
        <select name="gender" value={filterOption.gender} onChange={handleFilterChange} className="filtered-members-select">
          <option value="all">すべて</option>
          <option value="男性">男性</option>
          <option value="女性">女性</option>
          <option value="ノンバイナリー">ノンバイナリー</option>
          <option value="トランスジェンダー">トランスジェンダー</option>
          <option value="その他">その他</option>
        </select>
      </div>
      <div className="filtered-members-form-group">
        <label>年齢:</label>
        <div className="filtered-members-age-group">
          <select name="minAge" value={filterOption.minAge} onChange={handleFilterChange} className="filtered-members-select">
            {Array.from({ length: 53 }, (_, i) => i + 18).map(age => (
              <option key={age} value={age}>{age}</option>
            ))}
          </select>
          <span>から</span>
          <select name="maxAge" value={filterOption.maxAge} onChange={handleFilterChange} className="filtered-members-select">
            {Array.from({ length: 53 }, (_, i) => i + 18).map(age => (
              <option key={age} value={age}>{age}</option>
            ))}
          </select>
          <span>まで</span>
        </div>
      </div>
      <div className="filtered-members-form-group">
        <label>居住地:</label>
        <select name="location" value={filterOption.location} onChange={handleFilterChange} className="filtered-members-select">
          <option value="all">すべて</option>
          <option value="北海道">北海道</option>
          <option value="青森">青森</option>
          <option value="岩手">岩手</option>
          <option value="宮城">宮城</option>
          <option value="秋田">秋田</option>
          <option value="山形">山形</option>
          <option value="福島">福島</option>
          <option value="茨城">茨城</option>
          <option value="栃木">栃木</option>
          <option value="群馬">群馬</option>
          <option value="埼玉">埼玉</option>
          <option value="千葉">千葉</option>
          <option value="東京">東京</option>
          <option value="神奈川">神奈川</option>
          <option value="新潟">新潟</option>
          <option value="富山">富山</option>
          <option value="石川">石川</option>
          <option value="福井">福井</option>
          <option value="山梨">山梨</option>
          <option value="長野">長野</option>
          <option value="岐阜">岐阜</option>
          <option value="静岡">静岡</option>
          <option value="愛知">愛知</option>
          <option value="三重">三重</option>
          <option value="滋賀">滋賀</option>
          <option value="京都">京都</option>
          <option value="大阪">大阪</option>
          <option value="兵庫">兵庫</option>
          <option value="奈良">奈良</option>
          <option value="和歌山">和歌山</option>
          <option value="鳥取">鳥取</option>
          <option value="島根">島根</option>
          <option value="岡山">岡山</option>
          <option value="広島">広島</option>
          <option value="山口">山口</option>
          <option value="徳島">徳島</option>
          <option value="香川">香川</option>
          <option value="愛媛">愛媛</option>
          <option value="高知">高知</option>
          <option value="福岡">福岡</option>
          <option value="佐賀">佐賀</option>
          <option value="長崎">長崎</option>
          <option value="熊本">熊本</option>
          <option value="大分">大分</option>
          <option value="宮崎">宮崎</option>
          <option value="鹿児島">鹿児島</option>
          <option value="沖縄">沖縄</option>
        </select>
      </div>
      <div className="filtered-members-form-group">
        <label>専攻:</label>
        <select name="major" value={filterOption.major} onChange={handleFilterChange} className="filtered-members-select">
          <option value="all">すべて</option>
          <option value="数学">数学</option>
          <option value="物理学">物理学</option>
          <option value="化学">化学</option>
          <option value="生物">生物</option>
          <option value="地球・惑星">地球・惑星</option>
          <option value="機械">機械</option>
          <option value="システム制御">システム制御</option>
          <option value="電気・電子">電気・電子</option>
          <option value="農学">農学</option>
          <option value="材料">材料</option>
          <option value="応用化学">応用化学</option>
          <option value="薬学">薬学</option>
          <option value="医療">医療</option>
          <option value="情報工学">情報工学</option>
          <option value="知能・情報">知能・情報</option>
          <option value="建築">建築</option>
          <option value="都市・環境学">都市・環境学</option>
          <option value="土木工学">土木工学</option>
          <option value="商学">商学</option>
          <option value="経済学">経済学</option>
          <option value="法学">法学</option>
          <option value="社会学">社会学</option>
          <option value="文学">文学</option>
          <option value="外国語">外国語</option>
          <option value="心理学">心理学</option>
          <option value="教育">教育</option>
          <option value="哲学">哲学</option>
          <option value="宗教学">宗教学</option>
          <option value="政治学">政治学</option>
          <option value="歴史学">歴史学</option>
          <option value="その他">その他</option>
        </select>
      </div>
      <button onClick={handleApplyFilter} className="filtered-members-apply-button">適用</button>
    </div>
  );
};

export default FilteredMembers;
