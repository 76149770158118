import React, { useEffect, useState, createContext, useContext } from 'react';
import { auth } from '../firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc,setDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { getFunctions, httpsCallable } from 'firebase/functions';
import MenuBar from '../components/MenuBar';
import { FaFilter } from 'react-icons/fa';
import '../styles/MemberSearch.css';
import { app } from '../firebaseConfig';
import debounce from 'lodash.debounce';

const MemberContext = createContext([]);

export const useMembers = () => {
  return useContext(MemberContext);
};

const MemberSearch = () => {
  const [members, setMembers] = useState([]);
  const [user] = useAuthState(auth);
  const [filterOption, setFilterOption] = useState(null);
  const [lastDoc, setLastDoc] = useState(null); 
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFilterOption = async () => {
      if (user) {
        try {
          const filterDocRef = doc(db, 'filterOptions', user.uid);
          const filterDocSnap = await getDoc(filterDocRef);
          if (filterDocSnap.exists()) {
            const data = filterDocSnap.data();
            setFilterOption({
              ...data,
              minAge: parseInt(data.minAge, 10),
              maxAge: parseInt(data.maxAge, 10),
            });
          } else {
            const defaultFilterOption = {
              gender: 'all',
              location: 'all',
              major: 'all',
              maxAge: 70,
              minAge: 18,
            };
            await setDoc(filterDocRef, defaultFilterOption);
            setFilterOption(defaultFilterOption);
          }
        } catch (error) {
          console.error("Error fetching filter options:", error);
        }
      }
    };

    fetchFilterOption();
  }, [user]);

  const fetchMembers = async () => {
    if (!user || !filterOption || loading || !hasMore) {
      return;
    }

    setLoading(true);
    try {
      const functions = getFunctions(app, 'asia-northeast2');
      const fetchFilteredMembers = httpsCallable(functions, 'fetchFilteredMembers');

      const result = await fetchFilteredMembers({
        ...filterOption,
        lastDoc,
        limit: 80,
      });

      const newMembers = result.data;

      if (newMembers.length < 2) {
        setHasMore(false);
      }

      if (newMembers.length > 0) {
        setLastDoc(newMembers[newMembers.length - 1].id);
        setMembers((prevMembers) => [...prevMembers, ...newMembers]);
      }
    } catch (error) {
      console.error("Error fetching members:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (filterOption) {
      setHasMore(true);
      setLastDoc(null);
      setMembers([]);
      fetchMembers();
    }
  }, [filterOption]);

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 1) {
      fetchMembers();
    }
  };

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 200);  
    window.addEventListener('scroll', debouncedHandleScroll);
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [filterOption, lastDoc, hasMore]);

  const handleFilterApply = () => {
    navigate('/filtered_members', { state: { filterOption, members } });
  };

  const handleProfileClick = (member) => {
    navigate(`/other_member_profile/${member.id}`, { state: { members } });
  };

  const isNewMember = (member) => {
    if (!member || !member.creationTime) return false;
  
    // creationTime を Date オブジェクトに変換
    const creationDate = new Date(member.creationTime);
    const now = new Date();
  
    // 現在の日付との差を計算
    const diffTime = Math.abs(now - creationDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
    // 30日以内のメンバーを新しいとみなす
    return diffDays <= 5;
  };
  

  return (
    <MemberContext.Provider value={members}>
      <div className="member-search-container">
        <button onClick={() => handleFilterApply()} className="filter-button">
          <FaFilter /> 絞り込み
        </button>
        <div className="member-grid">
          {members.map(member => (
            <div key={member.id} className={`member-card ${isNewMember(member) ? 'new-member' : ''}`} onClick={() => handleProfileClick(member)}>
              <div className="image-container">
                <img src={member.photoURL || '/images/default.png'} alt="Profile" className="member-image" />
                {isNewMember(member) && <span className="new-badge">NEW</span>}
              </div>
              <div className="member-info">
                <span className="member-name">{member.name} ({member.age})</span><br />
                <span className="member-major">{member.location}/{member.major}</span>
              </div>
            </div>
          ))}
        </div>
        <MenuBar />
      </div>
    </MemberContext.Provider>
  );
};

export { MemberContext };

export default MemberSearch;
