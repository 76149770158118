import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db, auth } from '../../firebaseConfig';
import { collection, query, where, getDocs, updateDoc, doc, getDoc, addDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import '../../styles/AdminInquiry.css';

const AdminInquiry = () => {
  const [user, loading] = useAuthState(auth);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [inquiries, setInquiries] = useState([]);
  const [reports, setReports] = useState([]);
  const [title, setTitle] = useState('');
  const [response, setResponse] = useState('');
  const [selectedInquiry, setSelectedInquiry] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);
  const [reportedUser, setReportedUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdmin = async () => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        if (idTokenResult.claims.admin) {
          setIsAdmin(true);
          fetchInquiries();
          fetchReports();
        } else {
          setIsAdmin(false);
          navigate('/'); // 管理者でない場合はリダイレクト
        }
      }
    };

    if (!loading) {
      checkAdmin();
    }
  }, [user, loading, navigate]);

  const fetchInquiries = async () => {
    setLoadingData(true);
    const q = query(collection(db, 'inquiries'), where('status', '==', 'pending'));
    const querySnapshot = await getDocs(q);
    const inquiryData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));
    setInquiries(inquiryData);
    setLoadingData(false);
  };

  const fetchReports = async () => {
    setLoadingData(true);
    const q = query(collection(db, 'reports'), where('status', '==', 'pending'));
    const querySnapshot = await getDocs(q);
    const reportData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));
    setReports(reportData);
    setLoadingData(false);
  };

  const fetchReportedUser = async (userId) => {
    const userDoc = await getDoc(doc(db, 'members', userId));
    if (userDoc.exists()) {
      setReportedUser(userDoc.data());
    } else {
      setReportedUser(null);
    }
  };

  const handleSelectInquiry = (inquiry) => {
    setSelectedInquiry(inquiry);
    setSelectedReport(null);
    setTitle(inquiry.title || '');
    setResponse(inquiry.response || '');
  };

  const handleSelectReport = (report) => {
    setSelectedReport(report);
    setSelectedInquiry(null);
    setTitle(report.title || '');
    setResponse(report.response || '');
    fetchReportedUser(report.reportedUser);
  };

  const handleSendResponse = async () => {
    if ((!selectedInquiry && !selectedReport) || response.trim() === '' || title.trim() === '') return;

    try {
      if (selectedInquiry) {
        await updateDoc(doc(db, 'inquiries', selectedInquiry.id), { title, response, status: 'answered' });
        await addDoc(collection(db, 'notifications'), {
          userId: selectedInquiry.userId,
          title: `返信: ${title}`,
          content: response,
          createdAt: new Date(),
          read: false
        });
        setSelectedInquiry(null);
      } else if (selectedReport) {
        await updateDoc(doc(db, 'reports', selectedReport.id), { title, response, status: 'answered' });
        await addDoc(collection(db, 'notifications'), {
          userId: selectedReport.reportedUser,
          title: `返信: ${title}`,
          content: response,
          createdAt: new Date(),
          read: false
        });
        setSelectedReport(null);
        setReportedUser(null);
      }
      setTitle('');
      setResponse('');
      fetchInquiries(); // 更新後に再読み込み
      fetchReports(); // 更新後に再読み込み
    } catch (error) {
      console.error('Error sending response:', error);
    }
  };

  if (loading || loadingData) {
    return <div>Loading...</div>;
  }

  if (!isAdmin) {
    return <div>Access denied. You do not have permission to view this page.</div>;
  }

  return (
    <div className="admin-inquiry-container">
      <h2>User Inquiries and Reports</h2>
      {inquiries.length === 0 && reports.length === 0 ? (
        <div>No pending inquiries or reports.</div>
      ) : (
        <>
          <ul>
            {inquiries.map((inquiry) => (
              <li key={inquiry.id} className="inquiry-item" onClick={() => handleSelectInquiry(inquiry)}>
                <p>{inquiry.content}</p>
                <span className="inquiry-date">
                  {new Date(inquiry.createdAt.toMillis()).toLocaleString()}
                </span>
              </li>
            ))}
          </ul>
          <ul>
            {reports.map((report) => (
              <li key={report.id} className="inquiry-item" onClick={() => handleSelectReport(report)}>
                <p>{report.details}</p>
                <span className="inquiry-date">
                  {new Date(report.createdAt.toMillis()).toLocaleString()}
                </span>
              </li>
            ))}
          </ul>
        </>
      )}
      {(selectedInquiry || selectedReport) && (
        <div className="response-form">
          <h3>Respond to {selectedInquiry ? 'Inquiry' : 'Report'}</h3>
          <p><strong>{selectedInquiry ? 'Inquiry' : 'Report'}:</strong> {selectedInquiry ? selectedInquiry.content : selectedReport.details}</p>
          {selectedReport && reportedUser && (
            <div className="reported-user-info">
              <h4>Reported User Information</h4>
              <img src={reportedUser.photoURL || '/images/default.png'} alt="Reported User" className="reported-user-image" />
              <p><strong>Name:</strong> {reportedUser.name}</p>
            </div>
          )}
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter the title"
            className="response-title"
          />
          <textarea
            value={response}
            onChange={(e) => setResponse(e.target.value)}
            placeholder="Enter your response"
            rows="4"
            className="response-textarea"
          />
          <button onClick={handleSendResponse}>Send Response</button>
          <div className="response-preview">
            <h4>Response Preview:</h4>
            <p style={{ whiteSpace: 'pre-wrap' }}>{response}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminInquiry;
