import React, { useState } from 'react';
import { auth, db } from '../firebaseConfig';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, sendEmailVerification, onAuthStateChanged } from 'firebase/auth';
import { setDoc, doc, getDoc } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import '../styles/Signup.css';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validatePassword = (password) => {
    return password.length >= 8;
  };

  const handleEmailSignup = async (e) => {
    e.preventDefault();

    if (!agreeToTerms) {
      setError('利用規約とプライバシーポリシーに同意する必要があります。');
      return;
    }

    if (!validateEmail(email)) {
      setError('有効なメールアドレスを入力してください。');
      return;
    }

    if (!validatePassword(password)) {
      setError('パスワードは8文字以上である必要があります。');
      return;
    }

    const actionCodeSettings = {
      url: 'https://insei-match.net/login',
      handleCodeInApp: true,
    };

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await sendEmailVerification(user, actionCodeSettings);
      alert('確認メールを送りました。メールを確認してアカウントを有効にしてください。迷惑メールフォルダもご確認ください。');

      onAuthStateChanged(auth, async (currentUser) => {
        if (currentUser && currentUser.emailVerified) {
          await setDoc(doc(db, 'members', currentUser.uid), {
            email,
            isEnabled: true,
            createdAt: new Date(),
          });
          navigate('/complete-profile');
        } else {
          setError('メールアドレスの認証が完了していません。メールを確認してください。迷惑メールフォルダもご確認ください。');
        }
      });
    } catch (error) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          setError('このメールアドレスは既に使用されています。');
          break;
        case 'auth/invalid-email':
          setError('無効なメールアドレスです。');
          break;
        case 'auth/weak-password':
          setError('パスワードは8文字以上にしてください。');
          break;
        default:
          setError('アカウント作成中にエラーが発生しました。しばらくしてからもう一度お試しください。');
      }
    }
  };

  const handleGoogleSignup = async () => {
    if (!agreeToTerms) {
      setError('利用規約とプライバシーポリシーに同意する必要があります。');
      return;
    }

    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const userDocRef = doc(db, 'members', user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setError('このGoogleアカウントは既に登録されています。');
      } else {
        await setDoc(userDocRef, {
          email: user.email,
          isEnabled: true,
          createdAt: new Date(),
        });
        navigate('/login');
      }
    } catch (error) {
      setError('このGoogleアカウントは既に登録されています。');
    }
  };

  return (
    <div className="signup-container">
      <header className="header-top">
        <div className="logo">
          <Link to="/"><img src="/images/default1.png" alt="Logo" /></Link>
          院生マッチ
        </div>
      </header>
      <h2>会員登録</h2>
      <p className="age_warn">※本サービスは18歳以上（高校生は除く）の方のみご利用いただけます。</p>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleEmailSignup} className="signup-form">
        <input
          type="email"
          placeholder="メールアドレス"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="signup-input"
        />
        <input
          type="password"
          placeholder="パスワード(8文字以上)"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="signup-input"
        />
        <div className="terms-container">
          <input
            type="checkbox"
            id="agreeToTerms"
            checked={agreeToTerms}
            onChange={(e) => setAgreeToTerms(e.target.checked)}
          />
          <label htmlFor="agreeToTerms">
            <Link to="/terms">
              利用規約
            </Link>
            および
            <Link to="/privacy-policy">
              プライバシーポリシー
            </Link>
            に同意する
          </label>
        </div>
        <button type="submit" className="signup-button" disabled={!agreeToTerms}>
          登録
        </button>
      </form>
      <button
        onClick={handleGoogleSignup}
        className="google-signup-button"
        disabled={!agreeToTerms}
      >
        Googleアカウントで登録
      </button>
    </div>
  );
};

export default Signup;
