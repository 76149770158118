import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebaseConfig';
import { collection, query, where, getDocs, updateDoc, doc, addDoc, getDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link } from 'react-router-dom';
import MenuBar from '../components/MenuBar';
import '../styles/Iine.css';
import { BiLike } from "react-icons/bi";
import { BiSolidLike } from "react-icons/bi";
import { IoArrowRedoSharp } from "react-icons/io5";
import { FaClockRotateLeft } from "react-icons/fa6";

const Iine = () => {
  const [user] = useAuthState(auth);
  const [likes, setLikes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      fetchLikes();
      updateLikesCheck();
    }
  }, [user]);

  const fetchLikes = async () => {
    setLoading(true);
    const q = query(collection(db, 'likes'), where('to', '==', user.uid), where('status', '==', 'pending'));
    const querySnapshot = await getDocs(q);

    const likesData = await Promise.all(
      querySnapshot.docs.map(async (docSnapshot) => {
        const likeData = docSnapshot.data();
        const userDoc = await getDoc(doc(db, 'members', likeData.from));
        const userData = userDoc.data();

        return { id: docSnapshot.id, ...likeData, user: userData };
      })
    );

    setLikes(likesData);
    setLoading(false);
  };

  const updateLikesCheck = async () => {
    try {
      const q = query(collection(db, 'likes'), where('to', '==', user.uid), where('check', '==', false));
      const querySnapshot = await getDocs(q);
      querySnapshot.docs.forEach(async (docSnapshot) => {
        const likeRef = doc(db, 'likes', docSnapshot.id);
        await updateDoc(likeRef, { check: true });
      });
    } catch (error) {
      console.error('Error updating likes check:', error);
    }
  };

  const handleApprove = async (like) => {
    try {
      const likeRef = doc(db, 'likes', like.id);
      await updateDoc(likeRef, { status: 'approved' });

      const chatRef = await addDoc(collection(db, 'chats'), {
        participants: [user.uid, like.from],
        createdAt: new Date()
      });

      console.log('Chat document created successfully', chatRef.id);
      fetchLikes(); // 再度いいね一覧を更新
    } catch (error) {
      console.error('Error approving like and creating chat:', error);
    }
  };

  const handleReject = async (like) => {
    try {
      const likeRef = doc(db, 'likes', like.id);
      await updateDoc(likeRef, { status: 'rejected' });
      fetchLikes(); // 再度いいね一覧を更新
    } catch (error) {
      console.error('Error rejecting like:', error.message);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="iine-container">
      <div className="settings-header">
        <p className="settings-title">相手からのいいね</p>
        <Link to="/iine-not-display" className="bell-icon-link">
          <FaClockRotateLeft className="bell-icon" />
        </Link>
      </div>
      <div className="likes-list">
        {likes.map(like => (
          <div key={like.id} className="like-card">
            <Link to={`/other_member_profile/${like.from}`} className="like-card-link">
              <img src={like.user?.photoURL || '/images/default.png'} alt="Profile" className="like-card-image" onError={(e) => e.target.src = '/images/default.png'} />
              <div className="like-card-info">
                <h3>{like.user?.name || 'Unknown User'}</h3>
                <p>{like.user?.location || '--'}</p>
              </div>
            </Link>
            <div className="like-card-buttons">
              <button onClick={() => handleApprove(like)} className="like-card-button iine-approve"><BiSolidLike /></button>
              <button onClick={() => handleReject(like)} className="like-card-button iine-reject"><IoArrowRedoSharp /></button>
            </div>
          </div>
        ))}
      </div>
      <MenuBar />
    </div>
  );
};

export default Iine;
