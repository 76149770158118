import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { db, auth } from '../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import MenuBar from '../components/MenuBar';
import { FaRegBell } from 'react-icons/fa';
import '../styles/Settings.css';

const Settings = () => {
  const [user, loading, error] = useAuthState(auth);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);

  useEffect(() => {
    const fetchUnreadNotifications = async () => {
      if (user) {
        const q = query(
          collection(db, `members/${user.uid}/notifications`),
          where('read', '==', false)
        );
        const querySnapshot = await getDocs(q);
        setHasUnreadNotifications(!querySnapshot.empty);
      }
    };

    if (user) {
      fetchUnreadNotifications();
    }
  }, [user]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="settings-container">
      <div className="settings-header">
        <p className="settings-title">設定メニュー</p>
        <Link to="/settingnotification" className="bell-icon-link">
          <FaRegBell className="bell-icon" />
          {hasUnreadNotifications && <div className="unread-indicator"></div>}
        </Link>
      </div>
      <div className="settings-list">
        <Link to="/profile_edit" className="settings-item">
          <span className="settings-item-text">プロフィール編集</span>
        </Link>
        <Link to="/age_verification" className="settings-item">
          <span className="settings-item-text">年齢確認</span>
        </Link>
        <Link to="/accountsettings" className="settings-item">
          <span className="settings-item-text">アカウント設定</span>
        </Link>
        <Link to="/chat-not-display" className="settings-item">
          <span className="settings-item-text">チャット非表示一覧</span>
        </Link>
        <Link to="/settinginquiry" className="settings-item">
          <span className="settings-item-text">お問い合わせ</span>
        </Link>
      </div>
      <MenuBar />
    </div>
  );
};

export default Settings;
