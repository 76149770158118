import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Link } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { app, auth } from '../firebaseConfig';
import DOMPurify from 'dompurify';  // 追加: DOMPurifyのインポート
import MenuBar from '../components/MenuBar';
import '../styles/Inbox.css';

const Inbox = () => {
  const [user] = useAuthState(auth);
  const [chats, setChats] = useState([]);
  const [isAgeVerified, setIsAgeVerified] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      const functions = getFunctions(app, 'asia-northeast2');
      const fetchChatsWithLastMessage = httpsCallable(functions, 'fetchChatsWithLastMessage');

      fetchChatsWithLastMessage()
        .then((result) => {
          setChats(result.data.chats);
          setIsAgeVerified(result.data.isAgeVerified);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching chats:', error);
          setLoading(false);
        });
    }
  }, [user]);

  const formatDate = (timestamp) => {
    if (!timestamp) return '';

    const date = timestamp._seconds ? new Date(timestamp._seconds * 1000) : new Date(timestamp);
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0'); 

    return `${year}/${month}/${day}`;
  };

  const getChatDate = (chat) => {
    if (chat.lastMessage && chat.lastMessage.createdAt) {
      return new Date(chat.lastMessage.createdAt._seconds * 1000 + chat.lastMessage.createdAt._nanoseconds / 1000000);
    }
    if (chat.createdAt && chat.createdAt._seconds) {
      return new Date(chat.createdAt._seconds * 1000 + chat.createdAt._nanoseconds / 1000000);
    }
    return new Date(0);
  };

  const sanitize = (input) => {
    return DOMPurify.sanitize(input); // DOMPurifyを使って入力をサニタイズ
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="inbox-container">
      <p className="top_text">メッセージ</p>

      <ul className="chat-list">
        {chats
          .sort((a, b) => {
            const dateA = getChatDate(a);
            const dateB = getChatDate(b);
            return dateB - dateA;
          })
          .map(chat => {
            const participantData = chat.participantData;
            const lastMessage = chat.lastMessage;

            return (
              <li key={chat.id} className="chat-item">
                <Link to={`/chat/${chat.id}`} className="chat-link">
                  <img src={participantData?.photoURL || '/images/default.png'} alt="Profile" className="chat-avatar" />
                  <div className="chat-info">
                    <div className="chat-header">
                      <h3 className="chat-name">{sanitize(participantData?.name || '--')}</h3> {/* 名前をサニタイズ */}
                      <p className="chat-date">
                        {formatDate(lastMessage ? lastMessage.createdAt : chat.createdAt)}
                      </p>
                    </div>
                    <div className="chat-body">
                      <p className="chat-last-message">
                        {isAgeVerified 
                          ? (lastMessage ? (lastMessage.imageUrl ? '画像が送信されました' : sanitize(lastMessage.text)) : 'メッセージを送ってみましょう！')
                          : '年齢確認が必要です'}
                      </p>
                    </div>
                  </div>
                </Link>
              </li>
            );
          })}
      </ul>
      <MenuBar />
    </div>
  );
};

export default Inbox;
